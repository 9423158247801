import * as React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

export default function BlogPostTemplate({data}) {
  return (
    <Layout>
      <SEO title="Pedir acceso" description="Pedir acceso" hasJsonLd />
      <div className="wrapper">
        <iframe
          width="540"
          height="100%"
          src="https://e7b4fa4e.sibforms.com/serve/MUIFACLau3rPBY9OxQostAwgt7vXbQgzns5gdmnL_f4VzGw_WA5SA5KP3p3atiCQY1DLGKS6mLwtXuGFvuAn9_d680TiFZaBJ_hym64IIaDvkQQOObZloFUuuyw1T7T3CYdGZaMUtQmtZJXYFepFXEkrP5sPpDK_oikFd2ScNR9NgQbCJTDjTMqV3zuWdDUOfoReia7grWy4KfGb"
          frameborder="0"
          scrolling="auto"
          allowfullscreen
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '100%',
            height: '100vh',
            overflow: 'hidden',
          }}
        ></iframe>
      </div>
    </Layout>
  )
}
